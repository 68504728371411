import { ADMIN_FETCH_USERS } from '../actions/actionTypes/types'

export interface AdminState {
  userList: any
}

const INITIAL_STATE = {
  userList: null
}

const adminReducer = (state:AdminState = INITIAL_STATE, action: any) => {
  switch(action.type) {
    case ADMIN_FETCH_USERS: {
          return {...state, userList: action.payload }
    }
    default:
      return state
  }
  
  
}

export default adminReducer