import { SET_APP_INFO, SET_SAFE_AREA_INSETS, SET_STATUS_BAR_HEIGHT, SetAppInfoInterface, SetSafeAreaInsetsInterface, SetStatusBarHeightInterface} from "../actions/actionTypes/types";


export interface InfoStateInterface {
  application: string,
  applicationVersion: string,
  statusBarHeight: number | null,
  safeAreaInsets: {top: number, bottom: number, left: number, right: number} | null

}

const INITIAL_STATE = {
  application: "",
  applicationVersion: "",
  statusBarHeight: null,
  safeAreaInsets: null
}

const infoReducer = (
  state: InfoStateInterface = INITIAL_STATE,
  action: SetAppInfoInterface | SetStatusBarHeightInterface | SetSafeAreaInsetsInterface
) => {
  switch(action.type) {
    case SET_APP_INFO: {
      const newState = {...state, application: action.payload.application, applicationVersion: action.payload.applicationVersion}
      return newState
    }
    case SET_STATUS_BAR_HEIGHT: {
      const newState = {...state, statusBarHeight: action.payload};
      return newState
    }

    case SET_SAFE_AREA_INSETS: {
      const newState = {...state, safeAreaInsets: action.payload}
      return newState
    }
  
    default:
      return state;
  }
}

export default infoReducer;