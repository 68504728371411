import { lazy } from "react";
import { WINDOW_NAMES } from "./constants";

const { BACKGROUND, DESKTOP, INGAME, POPUPINGAME, NOTIFICATIONINGAME } =
  WINDOW_NAMES;

const BackgroundWindow = lazy(
  () => import("overwolf/features/backgroundWindow/BackgroundWindow")
);
const DesktopWindow = lazy(
  () => import("overwolf/features/desktopWindow/DesktopWindow")
);
const InGameWindow = lazy(
  () => import("overwolf/features/inGameWindow/InGameWindow")
);
const PopupInGameWindow = lazy(
  () => import("overwolf/features/popupWindow/PopupInGameWindow")
);
const NotificationInGameWindow = lazy(
  () => import("overwolf/features/notificationWindow/NotificationInGameWindow")
);

interface ICurrentPageProps {
  page: string;
}
export const CurrentPage = ({ page }: ICurrentPageProps) => {
  switch (page) {
    case BACKGROUND:
      return <BackgroundWindow />;
    case DESKTOP:
      return <DesktopWindow />;
    case INGAME:
      return <InGameWindow />;
    case POPUPINGAME:
      return <PopupInGameWindow />;
    case NOTIFICATIONINGAME:
      return <NotificationInGameWindow />;
    default:
      return <p>Loading ...</p>;
  }
};
