import axios from "axios";
import { GlobalError } from "../components/errors/GlobalError";
import { generateConfig, GetApplication, GetApplicationVersion, refreshTokenHandler } from "utilityFunctions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback } from "react";

export const usePatchPromoCodes = ( navigate: any) => {
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const dispatch = useAppDispatch();

  const handlePromoCodes = useCallback(async (retried = false) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const config = generateConfig(accessToken);

    try {
      const application = await GetApplication();
      const applicationVersion = GetApplicationVersion();
      const promotionCode = localStorage.getItem("promoCode");

      await axios.patch(
        `${BASE_URL}/api/users/promotion`,
        { application, applicationVersion, promotionCode },
        config);
    } catch (error: any) {
      if (error.response.status === 401 && !retried) {
        const refreshedTokens = await refreshTokenHandler(
          refreshToken,
          userInfo,
          dispatch,
          navigate
        );
        if (refreshedTokens) {
          handlePromoCodes(true);
        } else {
          GlobalError(error);
        }
      } else {
        GlobalError(error);
      }
    }
  }, [accessToken, refreshToken, userInfo, dispatch, navigate]);

  return { handlePromoCodes };
};
