/* eslint-disable no-undef */

export function initializeTray() {
  try {
    if (typeof window.overwolf === 'undefined') {
      console.error("Overwolf is not defined. Make sure you're running this inside the Overwolf environment.");
      return;
    }

    window.overwolf.os.tray.setMenu({
      menu_items: [
        { label: "Open", id: "open" },
        { label: "Hide", id: "hide" },
        { label: "Close Completely", id: "close" }
      ]
    }, function(result) {
      if (result.success) {
        console.log("Tray menu set successfully.");
      } else {
        console.error("Failed to set tray menu:", result.error);
      }
    });

    window.overwolf.os.tray.onMenuItemClicked.addListener(function(event) {
      console.log("Tray menu item clicked:", event);
      if (event.item === "open") {
        window.overwolf.windows.restore("desktop", function(result) {
          if (!result.success) {
            console.error("Failed to restore window:", result.error);
          }
        });
      } else if (event.item === "hide") {
        window.overwolf.windows.hide("desktop", function(result) {
          if (!result.success) {
            console.error("Failed to hide window:", result.error);
          }
        });
      } else if (event.item === "close") {
        const windowsToClose = ["desktop", "background", "in_game"];
        windowsToClose.forEach(windowName => {
          window.overwolf.windows.obtainDeclaredWindow(windowName, function(result) {
            if (result.success) {
              window.overwolf.windows.close(result.window.id, function(closeResult) {
                if (!closeResult.success) {
                  console.error(`Failed to close ${windowName} window:`, closeResult.error);
                }
              });
            } else {
              console.error(`Failed to obtain ${windowName} window:`, result.error);
            }
          });
        });
      }
    });

    window.overwolf.windows.onStateChanged.addListener(function(result) {
      console.log("Window state changed:", result);
      if (result.window_state === "closed") {
        window.overwolf.windows.hide("desktop", function(result) {
          if (!result.success) {
            console.error("Failed to hide window:", result.error);
          }
        });
      }
    });
  } catch (error) {
    console.error("Error initializing tray:", error);
  }
}

/* eslint-enable no-undef */
