import axios from "axios";
import { Dispatch } from "redux";

import { REFRESH_DOLLARS, RefreshDollarsInterface } from "../actionTypes/types";
import { generateConfig, refreshTokenHandler } from "../../../utilityFunctions";
import { UserInfoInterface } from "../../../types";

export const RefreshPromoDollars =
  (
    accessToken: string,
    refreshToken: string,
    userInfo: UserInfoInterface,
    navigate: any
  ) =>
  async (dispatch: Dispatch<RefreshDollarsInterface>) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const config = generateConfig(accessToken);

    if (!accessToken) {
      return;
    }

    try {
      const dollarsResponse = await axios.get(
        `${BASE_URL}/api/users/dollars`,
        config
      );
      if (dollarsResponse.status === 200) {
        dispatch({
          type: REFRESH_DOLLARS,
          payload: dollarsResponse.data.availableDollars,
        });
      }
    } catch (error: any) {
      if (!!error.response && error.response.status === 401) {
        const refreshedTokens = await refreshTokenHandler(
          refreshToken,
          userInfo,
          dispatch,
          navigate
        );
        const refreshedConfig = generateConfig(refreshedTokens?.accessToken);
        try {
          const refreshedDollarsResponse = await axios.get(
            `${BASE_URL}/api/users/dollars`,
            refreshedConfig
          );
          if (refreshedDollarsResponse.status === 200) {
            dispatch({
              type: REFRESH_DOLLARS,
              payload: refreshedDollarsResponse.data.availableDollars,
            });
          }
        } catch (error: any) {
          console.log("error refreshing coins");
        }
      } else {
        console.log("error refreshing coins");
      }
    }
  };
