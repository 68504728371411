import {useEffect, useState} from 'react'
import {CurrentPage} from './CurrentPage'
import {WINDOW_NAMES} from './constants'
import {getCurrentWindow} from 'utils'
import './OverwolfApp.css'

export const OverwolfApp = () => {
    const [page, setPage] = useState<string>('')

    useEffect(() => {
        async function preLoad() {
            if (process.env.NODE_ENV === 'development') {
                setPage(WINDOW_NAMES.DESKTOP)
            } else {
                const currentWindow = await getCurrentWindow()
                setPage(currentWindow)
            }
        }

        preLoad()
    }, [])

    return (
        <CurrentPage page={page}/>
    )
}
