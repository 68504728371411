import { MessageId, WINDOW_NAMES } from "../../overwolf/app/constants";

const { DESKTOP, INGAME, NOTIFICATIONINGAME } = WINDOW_NAMES;

export function SendLogInMoveToMeal(messageContent: any) {
  SendToOtherWindow(MessageId.LOGGED_IN_MOVE_TO_MEALS_DISPLAY, messageContent);
}

export function SendLogOutMoveToLogin() {
  SendToOtherWindow(MessageId.LOGGED_OUT_MOVE_TO_LOGIN, null);
}

export function SendRefreshCurrentLocation(actionType: string, newState: any) {
  SendToOtherWindow(MessageId.REFRESH_CURRENT_LOCATION, {
    type: actionType,
    payload: newState,
  });
}

export function SendChangeLanguage(language: string) {
  SendToOtherWindow(MessageId.CHANGE_LANGUAGE, language);
}

export function SendToOtherWindow(messageId: string, messageContent: any) {
  import("../../overwolf/helper/windowHelper").then((helper) => {
    helper.GetCurrentWindow().then((result) => {
      if (result.window.name === DESKTOP) {
        helper.SendMessage(INGAME, messageId, messageContent);
        helper.SendMessage(NOTIFICATIONINGAME, messageId, messageContent);
      } else {
        helper.SendMessage(DESKTOP, messageId, messageContent);
        helper.SendMessage(NOTIFICATIONINGAME, messageId, messageContent);
      }
    });
  });
}

export function SendInGameNotification(messageContent: any) {
  SendToAllWindow(MessageId.NOTIFICATION_IN_GAME, messageContent);
}

export function SendToAllWindow(messageId: string, messageContent: any) {
  import("../../overwolf/helper/windowHelper").then((helper) => {
    helper.SendMessage(INGAME, messageId, messageContent);
    helper.SendMessage(NOTIFICATIONINGAME, messageId, messageContent);
    helper.SendMessage(DESKTOP, messageId, messageContent);
  });
}
