import { Dispatch } from "redux";
import { OrderResponse, UserAddressInterface, UserInfoInterface } from "../../../types";

export const ADMIN_FETCH_USERS = "ADMIN_FETCH_USERS";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS";
export const UPDATE_USER_PERSONAL = "UPDATE_USER_PERSONAL";
export const FETCH_MEALS = "FETCH_MEALS";
export const CREATE_MEAL = "CREATE_MEAL";
export const UPDATE_MEAL = "UPDATE_MEAL";
export const ORDER_MEAL = "ORDER_MEAL";
export const REORDER_MEAL_LIST = "REORDER_MEAL_LIST";
export const SET_DEMO_ADDRESS = "SET_DEMO_ADDRESS";
export const SET_DEMO_PLACE_ID = "SET_DEMO_PLACE_ID";
export const UPDATE_ACCESS_TOKENS = "UPDATE_ACCESS_TOKENS";
export const UPDATE_USER_PHONE = "UPDATE_USER_PHONE";
export const REFRESH_USER_INFO = "REFRESH_USER_INFO";
export const SET_ERROR = "SET_ERROR";
export const DISABLE_NAVIGATION = "DISABLE_NAVIGATION";
export const SET_SCROLL_Y = "SET_SCROLL_Y";
export const OVERWOLF_WINDOW_LOGIN = "OVERWOLF_WINDOW_LOGIN";
export const OVERWOLF_WINDOW_LOGOUT = "OVERWOLF_WINDOW_LOGOUT";
export const OVERWOLF_REFRESH_AUTH_STATE_FROM_OTHER_WINDOW =
  "OVERWOLF_REFRESH_AUTH_STATE_FROM_OTHER_WINDOW";
export const OVERWOLF_REFRESH_MEALS_STATE_FROM_OTHER_WINDOW =
  "OVERWOLF_REFRESH_MEALS_STATE_FROM_OTHER_WINDOW";
export const REFRESH_COINS = "REFRESH_COINS";
export const REFRESH_DOLLARS = "REFRESH_DOLLARS";
export const SET_TRACK_GAME = "SET_TRACK_GAME";
export const SET_ENV = "SET_ENV";
export const SET_ORDER_CONFIRMATION = "SET_ORDER_CONFIRMATION";
export const SET_APP_INFO = "SET_APP_INFO";
export const RESET_NOTIFICATION_POLLING = "RESET_NOTIFICATION_POLLING";
export const SET_CURRENTLY_POLLING = "SET_CURRENTLY_POLLING";
export const SET_STATUS_BAR_HEIGHT = "SET_STATUS_BAR_HEIGHT";
export const SET_SAFE_AREA_INSETS = "SET_SAFE_AREA_INSETS";
export const FETCH_STATS = 'FETCH_STATS';
export const FETCH_STATS_REQUEST = 'FETCH_STATS_REQUEST';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE';
 
export interface SetSafeAreaInsetsInterface {
  type: typeof SET_SAFE_AREA_INSETS,
  payload: {top: number, bottom: number, left: number, right: number}
}

export interface SetStatusBarHeightInterface {
  type: typeof SET_STATUS_BAR_HEIGHT,
  payload: number
}

export interface SetCurrentlyPollingInterface {
 type: typeof SET_CURRENTLY_POLLING,
 payload: boolean
}

export interface ResetNotificationPollingInterface {
  type: typeof RESET_NOTIFICATION_POLLING,
  payload: boolean
}

export interface SetAppInfoInterface {
  type: typeof SET_APP_INFO,
  payload: {application: string, applicationVersion: string}
}
 
export interface SetEnvInterface {
  type: typeof SET_ENV,
  payload: any
}

export interface SetOrderConfirmationInterface {
  type: typeof SET_ORDER_CONFIRMATION,
  payload: OrderResponse
}

export interface SetTrackGameInterface {
  type: typeof SET_TRACK_GAME,
  payload: boolean
}

export interface RefreshCoinsInterface {
  type: typeof REFRESH_COINS,
  payload: number
}


export interface RefreshDollarsInterface {
  type: typeof REFRESH_DOLLARS
  payload: number
}


export interface OverwolfWindowLoginInterface {
  type: typeof OVERWOLF_WINDOW_LOGIN;
  payload: any;
}

export interface SetScrollY {
  type: typeof SET_SCROLL_Y;
  payload: number | null;
}

export interface DisableBottomNavInterface {
  type: typeof DISABLE_NAVIGATION;
  payload: boolean;
}

export interface SetErrorDispatchInterface {
  type: typeof SET_ERROR;
  payload: any;
}



export interface RefreshUserInfoDispatchInterface {
  type: typeof REFRESH_USER_INFO;
  payload: UserInfoInterface;
}

export interface UpdateUserPhoneDispatchInterface {
  type: typeof UPDATE_USER_PHONE;
  payload: string;
}

export interface UpdateAccessTokensDispatchInterface {
  type: typeof UPDATE_ACCESS_TOKENS;
  payload: { accessToken: string; refreshToken: string };
}

export interface SetDemoAddressDispatchInterface {
  type: typeof SET_DEMO_ADDRESS;
  payload: {address: UserAddressInterface, placeId: string | undefined};
}

export interface SetDemoPlaceId {
  type: typeof SET_DEMO_PLACE_ID,
  payload: string | null
}

export interface AdminFetchUsersDispatchInterface {
  type: typeof ADMIN_FETCH_USERS;
  payload: any;
}

export interface ReorderMealListDispatchInterface {
  type: typeof REORDER_MEAL_LIST;
  payload: any;
}

export interface LoginDispatchInterface {
  type: typeof LOGIN_USER;
  payload: any;
}
export interface UpdateAddressDispatchInterface {
  type: typeof UPDATE_USER_ADDRESS;
  payload: any;
}
export interface UpdatePersonalInfoDispatchInterface {
  type: typeof UPDATE_USER_PERSONAL;
  payload: any;
}
export interface FetchMealsDispatchInterface {
  type: typeof FETCH_MEALS;
  payload: any;
}
export interface LogoutDispatchInterface {
  type: typeof LOGOUT_USER;
}

export interface CreateMealDispatchInterface {
  type: typeof CREATE_MEAL;
  payload: any;
}

export interface OrderMealDispatchInterface {
  type: typeof ORDER_MEAL;
  payload: any;
}

export interface UpdateMealDispatchInterface {
  type: typeof UPDATE_MEAL;
  payload: any;
}

export interface FetchStatsRequestInterface {
    type: typeof FETCH_STATS_REQUEST;
}

export interface FetchStatsSuccessInterface {
    type: typeof FETCH_STATS_SUCCESS;
    payload: StatsInterface;
}

export interface FetchStatsFailureInterface {
    type: typeof FETCH_STATS_FAILURE;
    payload: string;
}

export interface StatsInterface {
    totalUsers: number;
    totalCountedGamingMinutes: number;
    totalEarnedCoins: number;
}
export type Action = {
  type: string;
  payload?: any;
  dispatch?: Dispatch;
};
