import { useAppSelector } from "store/hooks";

const useApplicationInfo = () => {
  const application = useAppSelector((state: any) => state?.info?.application);
  const applicationVersion = useAppSelector((state: any) => state?.info?.applicationVersion)


  return { application, applicationVersion };
};

export default useApplicationInfo;