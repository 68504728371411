import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useAppSelector } from "store/hooks";
import { MsalProvider } from "@azure/msal-react";

type Props = {
  children: any;
};

export const ThirdPartyAuthProviders: React.FC<Props> = ({ children }) => {
  const env = useAppSelector((state) => state?.auth?.env);
  const REACT_APP_GOOGLE_CLIENT_ID = env?.REACT_APP_GOOGLE_CLIENT_ID;
  const REACT_APP_MICROSOFT_APP_ID = env?.REACT_APP_MICROSOFT_APP_ID;

  const msalConfig = {
    auth: {
      clientId: REACT_APP_MICROSOFT_APP_ID,
      authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: window.location.protocol + "//" + window.location.host,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </GoogleOAuthProvider>
  );
};
