import React, { Component, ErrorInfo } from "react";
import BackgroundColor from "./BackgroundColor";
import { BorderedContainer } from "./BorderedContainer";
import logoSmall from "../assets/images/logo-dead.png";

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    console.error(error, errorInfo);
    this.handleReload = this.handleReload.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleReload() {
    window.location.reload();
  }

  handleRedirect() {
    window.location.href = "/";
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <BackgroundColor>
          <div className="flex justify-center items-center text-slate-200">
            <BorderedContainer className="w-full lg:w-1/2 my-32">
              <div className="flex flex-col space-y-2 justify-center items-center pb-10 pt-4">
                <h1 className=" text-xl">Oops! Something went wrong. </h1>
                <img className="h-32" src={logoSmall} alt="liifer" />
                <div className="flex flex-col justify-center items-center space-x-1">
                  <span>Either</span>
                  <button
                    type="button"
                    className="bg-blue-600 mx-1 px-2 py-1 text-white hover:bg-blue-700 shadow-lg hover:shadow-sm transition duration-500 ease-in-out rounded-full whitespace-nowrap"
                    onClick={this.handleReload}
                  >
                    Reload
                  </button>
                  <span>or go back to the</span>
                  <button
                    type="button"
                    className="bg-blue-600 mx-1 px-2 py-1 text-white hover:bg-blue-700 shadow-lg hover:shadow-sm transition duration-500 ease-in-out rounded-full whitespace-nowrap"
                    onClick={this.handleRedirect}
                  >
                    Dashboard
                  </button>
                </div>
              </div>
            </BorderedContainer>
          </div>
        </BackgroundColor>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
