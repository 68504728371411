import { useAppSelector } from "../../store/hooks";
import { MealResponse } from "types";

const useFilteredUserMeals = () => {
  const userMeals = useAppSelector((state) => state.meals.userMeals);

  const filteredUserMeals = userMeals?.filter(
    (meal: MealResponse) => !meal?.name?.includes("Should not display")
  ).sort((a: any, b: any) => {
    if (a.active === b.active) return a.index < b.index ? -1 : 1;
    return a.active && !b.active ? -1 : 1;
  });

  return filteredUserMeals;
};

export default useFilteredUserMeals;
