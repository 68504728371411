import { NavigateFunction } from "react-router-dom";

function ClearData() {
  window.localStorage.removeItem("user");
  window.sessionStorage.clear();
}

export function LogoutOperations(navigate: NavigateFunction) {
  ClearData();
  navigate("/login");
}
