import React from "react";
import { IsRunningOverwolf } from "../utilityFunctions";

interface Props {
  imageBackground?: boolean;
  children: any;
}

const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
const isWebAppOnIOS =
  isTouchDevice && /iPad|iPhone|iPod/.test(navigator.userAgent);

const BackgroundColor: React.FC<Props> = ({ children, imageBackground }) => {
  return (
    <>
      <div
        className={`${
          isWebAppOnIOS ? "background-IOS" : ""
        } w-full h-full -mt-2`}
        style={
          isWebAppOnIOS
            ? {}
            : {
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: IsRunningOverwolf()
                  ? "calc(100vh - 20px)"
                  : "calc(100vh + 10px)",
              }
        }
      >
        {children}
      </div>
    </>
  );
};

export default React.memo(BackgroundColor);
