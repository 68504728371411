import { UserAddressInterface } from "../../types";
import {
  LOGIN_USER,
  LOGOUT_USER,
  OVERWOLF_REFRESH_AUTH_STATE_FROM_OTHER_WINDOW,
  OVERWOLF_WINDOW_LOGIN,
  OVERWOLF_WINDOW_LOGOUT,
  REFRESH_USER_INFO,
  RESET_NOTIFICATION_POLLING,
  SET_CURRENTLY_POLLING,
  SET_DEMO_ADDRESS,
  SET_DEMO_PLACE_ID,
  SET_ENV,
  SET_SCROLL_Y,
  UPDATE_ACCESS_TOKENS,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_PERSONAL,
  UPDATE_USER_PHONE,
} from "../actions/actionTypes/types";
import { IsRunningOverwolf } from "../../utilityFunctions";
import {
  SendLogInMoveToMeal,
  SendLogOutMoveToLogin,
  SendRefreshCurrentLocation,
} from "../../overwolf/helper/windowCommunicationHelper";

export interface AuthStateInterface {
  accessToken: string | null;
  refreshToken: string | null;
  userInfo: any;
  demoAddress: UserAddressInterface | null;
  scrollYPosition: number | null;
  env: null | any[],
  resetNotificationPolling: boolean,
  currentlyPolling: boolean | null,
  demoPlaceId: string | null,
}

const INITIAL_STATE = {
  accessToken: null,
  refreshToken: null,
  userInfo: null,
  demoAddress: null,
  tokenIsExpired: false,
  scrollYPosition: null,
  env: null,
  resetNotificationPolling: false,
  currentlyPolling: null,
  demoPlaceId: null,
};

const authReducer = (
  state: AuthStateInterface = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {

    case SET_CURRENTLY_POLLING: {
      const newState = {...state, currentlyPolling: action.payload}
      sendOverwolfStateOverWindow(newState);
      return newState;
    }

    case RESET_NOTIFICATION_POLLING: {
      const newState = {...state, resetNotificationPolling: action.payload}
      sendOverwolfStateOverWindow(newState)
      return newState
    }

    case SET_ENV: {
      const newEnv = {...state.env, ...action.payload};
      const newState = {...state, env: newEnv };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }

    case LOGIN_USER: {
      const result = {
        ...state,
        accessToken: action.payload.accessToken,
        userInfo: action.payload.userInfo,
        refreshToken: action.payload.refreshToken,
      };
      console.log("LOGIN_USER call, here is result", result)
      if (IsRunningOverwolf()) {
        SendLogInMoveToMeal(result);
      }

      return result;
    }
    case LOGOUT_USER: {
      if (IsRunningOverwolf()) {
        SendLogOutMoveToLogin();
      }
      const newState = { 
        accessToken: null,
        refreshToken: null,
        userInfo: null,
        demoAddress: null,
        tokenIsExpired: false,
        scrollYPosition: null,
        env: state.env,
        resetNotificationPolling: false,
        currentlyPolling: null,
      }
      return newState;
    }
    case UPDATE_USER_ADDRESS: {
      const newState = { ...state, userInfo: action.payload };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }
    case UPDATE_USER_PERSONAL: {
      const newState = { ...state, userInfo: action.payload };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }
    case SET_DEMO_ADDRESS: {
      return { ...state, demoAddress: action.payload };
    }
    case SET_DEMO_PLACE_ID: {
     return {...state, demoPlaceId: action.payload }
    }

    case UPDATE_ACCESS_TOKENS: {
      const newState = { ...state, accessToken: action.payload.accessToken };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }
    case UPDATE_USER_PHONE: {
      const newUserInfo = {
        ...action.payload.prevUserInfo,
        phone: action.payload.newNumber,
      };
      const newState = {
        ...state,
        userInfo: newUserInfo,
      };
      const newAuthData = {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userInfo: newUserInfo,
      };
      const localStorageUserInfo = localStorage.getItem("user");
      if (!!localStorageUserInfo) {
        localStorage.setItem("user", JSON.stringify(newAuthData));
      } else {
        sessionStorage.setItem("user", JSON.stringify(newAuthData));
      }

      sendOverwolfStateOverWindow(newState);
      return newState;
    }
    case REFRESH_USER_INFO: {
    
      const newState = { ...state, userInfo: { ...action.payload.userInfo } };
      const newAuthData = {
        userInfo: action.payload.userInfo,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

      const localStorageUserInfo = localStorage.getItem("user");
      if (!!localStorageUserInfo) {
        localStorage.setItem("user", JSON.stringify(newAuthData));
      } else {
        sessionStorage.setItem("user", JSON.stringify(newAuthData));
      }
      sendOverwolfStateOverWindow(newState);
      return newState;
    }
    case SET_SCROLL_Y: {
      return { ...state, scrollYPosition: action.payload };
    }
    case OVERWOLF_WINDOW_LOGIN: {
      console.log("this is overwolf window login payload ", action.payload);
      return { ...action.payload };
    }
    case OVERWOLF_WINDOW_LOGOUT: {
      const newState = { 
        accessToken: null,
        refreshToken: null,
        userInfo: null,
        demoAddress: null,
        tokenIsExpired: false,
        scrollYPosition: null,
        env: state.env,
        resetNotificationPolling: false,
        currentlyPolling: null,
      }
      return newState;
    }
    case OVERWOLF_REFRESH_AUTH_STATE_FROM_OTHER_WINDOW: {
      return action.payload;
    }
    default:
      return state;
  }
};

function sendOverwolfStateOverWindow(newState: any) {

  if (IsRunningOverwolf()) {
    SendRefreshCurrentLocation(
      OVERWOLF_REFRESH_AUTH_STATE_FROM_OTHER_WINDOW,
      newState
    );
  }
}

export default authReducer;
