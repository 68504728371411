import {
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE,
  StatsInterface
} from '../actions/actionTypes/types';

interface StatsState {
  stats: StatsInterface | null;
  loading: boolean;
  error: string | null;
}

const initialState: StatsState = {
  stats: null,
  loading: false,
  error: null
};

export const stats = (state = initialState, action: { type: any; payload: any; }): StatsState => {
  switch (action.type) {
      case FETCH_STATS_REQUEST:
          return {
              ...state,
              loading: true
          };
      case FETCH_STATS_SUCCESS:
          return {
              ...state,
              loading: false,
              stats: action.payload
          };
      case FETCH_STATS_FAILURE:
          return {
              ...state,
              loading: false,
              error: action.payload
          };
      default:
          return state;
  }
};

export default stats;