import Swal from "sweetalert2";
import withReactContent, {
  ReactSweetAlertOptions,
} from "sweetalert2-react-content";
import { ConvertError } from "../../Helper/ErrorMessageHelper";
import GlobalErrorGenericErrorImage from "../../assets/images/logo-dead.png";
import i18n from "i18next";
import { RefreshTokenExpiredException } from "../../Exception/RefreshTokenExpiredException";
import { GetApplication } from "utilityFunctions";

export const GlobalError = async (
  error: any,
  okayPostAction?: () => void,
  cancelPostAction?: () => void
) => {
  if (error instanceof RefreshTokenExpiredException) {
    return;
  }

  const MySwal = withReactContent(Swal);

  const convertedError = ConvertError(error);

  const checkForIosPlatform = async () => {
    const appType = await GetApplication();
    if (appType === "IOS_CLIENT") {
      return true;
    } else return false;
  };

  const options: ReactSweetAlertOptions = {
    position: (await checkForIosPlatform()) ? "center" : "top",
    title: !i18n.isInitialized ? "Attention" : i18n.t("error.sorry"),
    text: !i18n.isInitialized
      ? "An error occurred. Please try again later or contact us at support@liifer.com."
      : convertedError.errorMessage,
    confirmButtonText: !i18n.isInitialized ? "okay" : i18n.t("form.okay"),
    showCancelButton: !!cancelPostAction,
    customClass: {
      cancelButton:
        "bg-blue-600 mx-1 px-3 md:px-4 py-3 text-white hover:bg-blue-700 shadow-lg hover:shadow-sm transition duration-500 ease-in-out rounded-full whitespace-nowrap",
      confirmButton:
        "bg-blue-600 mx-1 px-3 md:px-4 py-3 text-white hover:bg-blue-700 shadow-lg hover:shadow-sm transition duration-500 ease-in-out rounded-full whitespace-nowrap",
    },
    reverseButtons: true,
    buttonsStyling: false,
  };

  if (convertedError.isGenericError) {
    options.imageUrl = GlobalErrorGenericErrorImage;
    options.imageAlt = "";
    options.imageWidth = 300;
    options.imageHeight = 268;
  }

  MySwal.fire(options).then(function (result) {
    if (result.dismiss === Swal.DismissReason.cancel) {
      if (!!cancelPostAction) {
        cancelPostAction();
      }
    } else {
      if (!!okayPostAction) {
        okayPostAction();
      }
    }
  });
};
