import {
  DISABLE_NAVIGATION,
  FETCH_MEALS,
  ORDER_MEAL,
  OVERWOLF_REFRESH_MEALS_STATE_FROM_OTHER_WINDOW,
  REORDER_MEAL_LIST,
 REFRESH_COINS,
 SET_TRACK_GAME,
 SET_ORDER_CONFIRMATION,
 REFRESH_DOLLARS,
} from "../actions/actionTypes/types";
import { IsRunningOverwolf } from "../../utilityFunctions";
import { SendRefreshCurrentLocation } from "../../overwolf/helper/windowCommunicationHelper";
import { MealResponse, OrderResponse } from "types";

export interface MealsStateInterface {
  userMeals: MealResponse[] | null;
  navigationIsDisabled: boolean;
  searchRadius: number | null;
  availableCoins: number | null;
  availablePromoDollars: number | null;
  trackingGame: boolean;
  orderConfirmation: null | OrderResponse,

}

const INITIAL_STATE = {
  userMeals: null,
  navigationIsDisabled: false,
  searchRadius: null,
  availableCoins: null,
  availablePromoDollars: null,
  trackingGame: false,
  orderConfirmation: null
};

const mealsReducer = (
  state: MealsStateInterface = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {

    case SET_ORDER_CONFIRMATION: {
      const newState = {...state, orderConfirmation: action.payload}
      sendOverwolfStateOverWindow(newState);
      return newState
    }

    case SET_TRACK_GAME: {
    const newState = {...state, trackingGame: action.payload}
    sendOverwolfStateOverWindow(newState)
    return newState
    }

    case REFRESH_DOLLARS: {
      const newState = {...state, availableDollars: action.payload};
      sendOverwolfStateOverWindow(newState);
      return newState
    }

    case REFRESH_COINS: {
      const newState = {...state, availableCoins: action.payload}
      sendOverwolfStateOverWindow(newState)
      return newState

    }
    case FETCH_MEALS: {
      const newState = { ...state, userMeals: [...action.payload] };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }
   
    case ORDER_MEAL: {
      const newState = { ...state, activeOrder: action.payload };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }
    case REORDER_MEAL_LIST: {
      const newState = { ...state, userMeals: action.payload };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }

    case DISABLE_NAVIGATION: {
      const newState = { ...state, navigationIsDisabled: action.payload };
      sendOverwolfStateOverWindow(newState);
      return newState;
    }

    case OVERWOLF_REFRESH_MEALS_STATE_FROM_OTHER_WINDOW: {
      return action.payload;
    }

    default:
      return state;
  }
};

function sendOverwolfStateOverWindow(newState: any) {
  if (IsRunningOverwolf()) {
    SendRefreshCurrentLocation(
      OVERWOLF_REFRESH_MEALS_STATE_FROM_OTHER_WINDOW,
      newState
    );
  }
}

export default mealsReducer;
