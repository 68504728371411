export const WINDOW_NAMES = {
  BACKGROUND: "background",
  SETTINGS: "settings",
  DEVELOPMENT: "development",
  INGAME: "in_game",
  DESKTOP: "desktop",
  POPUPINGAME: "in_game_popup",
  NOTIFICATIONINGAME: "in_game_notification",
};

export const OVERWOLF_HOOKS_OPTIONS = {
  displayLog: process.env.NODE_ENV === "production",
};

export const kHotkeys = {
  toggleInGameWindow: "show_hide_in_game_window_hotkey",
};

export const enum WindowState {
  CLOSED = "closed",
  MINIMIZED = "minimized",
  HIDDEN = "hidden",
  NORMAL = "normal",
  MAXIMIZED = "maximized",
}

export const enum MessageId {
  LOGGED_IN_MOVE_TO_MEALS_DISPLAY = "0",
  LOGGED_OUT_MOVE_TO_LOGIN = "1",
  REFRESH_CURRENT_LOCATION = "2",
  CHANGE_LANGUAGE = "3",
  NOTIFICATION_IN_GAME = "4",
  NOTIFICATION_READ = "5",
}
