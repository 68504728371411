import { ErrorMessageResponse, PaysafeError, StripeError } from "../types";
import i18n from "i18next";
import { store } from "store/store";

function GetExceptionType(type: string) {
  return type.substring(type.lastIndexOf("/") + 1);
}

export interface Error {
  errorType: string;
  errorMessage: string;
  isGenericError: boolean;
}

export function ConvertError(error: any): Error {
  const state = store.getState();
  const REACT_APP_STORE_MAINTENANCE_MODE =
    state?.auth?.env?.REACT_APP_STORE_MAINTENANCE_MODE;

  const exceptionsListToReplaceInStoreMaintenanceMode = [
    "RestaurantServiceException",
    "RestaurantServiceFatalException",
    "RestaurantNotFoundException",
    "RestaurantMenuNotFoundException",
    "InvalidOrderItemOptionException",
    "InvalidOrderItemException",
    "InvalidOrderException",
    "MealOrderFailedException",
    "OrderingDisabledException",
    "InternalServerException",
  ];

  let response: Error = {
    errorType: "",
    errorMessage: "",
    isGenericError: false,
  };

  if (typeof error === "string") {
    response.errorType = error;
    response.errorMessage = i18n.t(`exceptions.${response.errorType}`);
    return response;
  }

  if (IsAnErrorMessageResponse(error?.response?.data)) {
    response.errorType = GetErrorMessageFromResponse(
      error.response.data as ErrorMessageResponse
    );
    if ("MinimumOrderAmountException" === response.errorType) {
      response.errorMessage = i18n.t(`exceptions.MinimumOrderAmountException`, {
        amount: error.response.data.detail.substring(
          error.response.data.detail.lastIndexOf(" ") + 1
        ),
      });
    } else if ("PaymentCardDeclinedException" === response.errorType) {
      response.errorMessage = i18n.t(
        `exceptions.PaymentCardDeclinedException`,
        {
          reason: error.response.data.title,
        }
      );
    } else if (
      REACT_APP_STORE_MAINTENANCE_MODE === "true" &&
      exceptionsListToReplaceInStoreMaintenanceMode.includes(response.errorType)
    ) {
      response.errorMessage = i18n.t("maintenance.warningLong");
    } else {
      response.errorMessage = i18n.t(`exceptions.${response.errorType}`);
    }
  } else if (IsAnStripeMessage(error)) {
    if (error.code === "setup_intent_authentication_failure") {
      response.errorMessage = i18n.t("error.cardAuthenticationFailure");
    } else {
      response.errorMessage = error.message;
    }
  } else if (IsAnPaysafeErrorMessage(error)) {
    if (error.displayMessage === "Invalid fields: expiry date.") {
      response.errorMessage = i18n.t("error.invalidExpiryDate");
    } else {
      response.errorMessage = error.displayMessage;
    }
  } else if (!!error.maxAmountExceededMessage) {
    response.errorMessage = `${i18n.t("error.tooHighPriceOne")} ${
      error.maxAmountExceededMessage
    } ${i18n.t("error.tooLowPriceTwo")}`;
  } else if (
    error?.response?.data?.error === "Conflict" &&
    error?.response?.data?.status === 409
  ) {
    response.errorMessage = i18n.t(
      "exceptions.ConflictingSaveRequestException"
    );
  } else if ("customError" in error) {
    response.errorMessage = i18n.t(error.customError);
  } else if (
    error.restaurantNoDeliveryResponse === "CUSTOMER_ADDRESS_OUT_OF_RANGE"
  ) {
    response.errorMessage = i18n.t(
      "restaurantNoDeliveryResponse.customerAddressOutOfRange"
    );
  } else {
    response.errorMessage =
      REACT_APP_STORE_MAINTENANCE_MODE === "true"
        ? i18n.t("maintenance.warningLong")
        : i18n.t("error.generic");
    response.isGenericError = true;
  }

  return response;
}

function GetErrorMessageFromResponse(
  errorMessageResponse: ErrorMessageResponse
) {
  return GetExceptionType(errorMessageResponse.type);
}

function IsAnErrorMessageResponse(obj: any): obj is ErrorMessageResponse {
  return (
    !!obj &&
    "type" in obj &&
    "title" in obj &&
    "statusCode" in obj &&
    "status" in obj &&
    "detail" in obj &&
    "instance" in obj
  );
}

function IsAnPaysafeErrorMessage(obj: any): obj is PaysafeError {
  return (
    !!obj &&
    "code" in obj &&
    "displayMessage" in obj &&
    "detailedMessage" in obj &&
    "correlationId" in obj
  );
}

function IsAnStripeMessage(obj: any): obj is StripeError {
  return !!obj && "type" in obj && "code" in obj && "message" in obj;
}
