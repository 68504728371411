import reduxThunk from "redux-thunk";
import {composeWithDevTools} from 'redux-devtools-extension';
import reducers from "./reducers/index";
import { createStore, applyMiddleware } from "redux";


export const store = createStore(reducers, composeWithDevTools(applyMiddleware(reduxThunk)));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootStore = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch;

